@tailwind base;
@tailwind components;
.devider {
  @apply w-full grid grid-cols-3 gap-3;
}
.modal {
  @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 rounded-md bg-white;
}
.tab-selected {
  @apply inline-block w-44 text-center border-main border-b-2;
}
.tab-unselected {
  @apply inline-block w-44 text-center;
}
.tab-text-selected {
  @apply block text-base leading-normal font-bold hover:opacity-60 w-full py-1 text-main;
}
.tab-text-unselected {
  @apply block text-base leading-normal font-bold hover:opacity-60 w-full py-1 text-gray-400;
}
li.paginator {
  @apply inline-block text-sm px-1;
}
@tailwind utilities;
