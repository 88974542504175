/* font  */
@font-face {
  font-family: "pretendardR";
  src: url(./assets/font/pretendard/Pretendard-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardT";
  src: url(./assets/font/pretendard/Pretendard-Thin.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardSB";
  src: url(./assets/font/pretendard/Pretendard-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardM";
  src: url(./assets/font/pretendard/Pretendard-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardL";
  src: url(./assets/font/pretendard/Pretendard-Light.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardEL";
  src: url(./assets/font/pretendard/Pretendard-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardB";
  src: url(./assets/font/pretendard/Pretendard-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "pretendardEB";
  src: url(./assets/font/pretendard/Pretendard-ExtraBold.woff2) format("woff2");
}
body {
  font-family: "pretendardR", sans-serif;
}
.pretendardT {
  font-family: "pretendardT";
}
.pretendardR {
  font-family: "pretendardR";
}
.pretendardSB {
  font-family: "pretendardSB";
}
.pretendardM {
  font-family: "pretendardM";
}
.pretendardL {
  font-family: "pretendardL";
}
.pretendardEL {
  font-family: "pretendardEL";
}
.pretendardB {
  font-family: "pretendardB";
}
.pretendardEB {
  font-family: "pretendardEB";
}
@font-face {
  font-family: "noto";
  src: url(./assets/font/notosans/notosans-regular.otf);
}
@font-face {
  font-family: "nanum";
  src: url(./assets/font/nanum/NanumGothic.otf);
}
@font-face {
  font-family: "stencilstd";
  src: url(./assets/font/stencilstd/StencilStd.otf);
}
@font-face {
  font-family: sansation;
  src: url(/src/assets/font/sansation/Sansation-Regular.woff2);
}
@font-face {
  font-family: gangwon;
  src: url(./assets/font/gangwon/gangwon.woff2) format("woff2");
}
.sansation {
  font-family: sansation;
}
.gangwon {
  font-family: gangwon;
}
.stencilstd {
  font-family: "stencilstd";
}
.noto {
  font-family: "noto", sans-serif;
}
.nanum {
  font-family: "nanum", sans-serif;
}
.imp-frame {
  /* padding-top: 100px; */
  background-color: rgba(121, 121, 121, 0.5);
}

/* 전체 */

html {
  scroll-behavior: smooth;
}
.smoothScroll {
  scroll-behavior: smooth;
}
.keep-all {
  word-break: keep-all;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 0.8em;
}
select {
  appearance: none;
  background-image: url(./assets/icon/arrow.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.select_arrow {
  appearance: none;
  background-image: url(./assets/icon/arrow.png);
  background-repeat: no-repeat;
  background-position: right center;
}
@media screen and (max-width: 1023px) {
  .select_arrow {
    appearance: none;
    background-image: url(./assets/icon/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: right center;
  }
}
.h-fix-content {
  min-height: calc(100vh - 11rem);
}
.ellipsis1 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ellipsis2 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ellipsis4 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.ellipsis5 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.ellipsis8 {
  display: -webkit-box;
  /* 줄 갯수 */
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
select option {
  box-shadow: none;
  -webkit-box-shadow: none;
}
/* aside */
.MuiDrawer-paper {
  width: 360px;
  border-radius: 0px 24px 0px 0px;
}
@media screen and (max-width: 360px) {
  .MuiDrawer-paper {
    width: 100%;
  }
}
.removeScroll::-webkit-scrollbar {
  display: none;
}
/* ------------- HOME */

/* swiper */
.swiper-horizontal > .swiper-pagination-bullets {
  text-align: center;
  padding: 0 5%;
}
.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet {
  cursor: pointer;
  margin: 0 4px;
}
.swiper-horizontal
  > .swiper-pagination-bullets
  > .swiper-pagination-bullet-active {
  background-color: #fff;
}
.bannerRatio {
  padding-top: calc(100% * 674 / 1443);
}
/* @media screen and (max-width: 767px) {
  .bannerRatio {
    padding-top: calc(100% * 800 / 1400);
  }
} */
.placeBanner .placeEffect::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  transition: all 0.35s;
}
.placeBanner:hover > .placeEffect::after {
  opacity: 1;
}
.placeBanner:hover .placeEffect .placeText {
  top: 0;
}
.placeBanner:hover .placeEffect .placeImg {
  transform: scale(125%);
}
.placeBannerRatio {
  padding-top: calc(100% * 170 / 225);
}
.text-shadow {
  text-shadow: #fff 0 0 5px;
}
/* category */
.leftGuide {
  position: relative;
  border-left: 1px solid #b6b5b5;
}
.rightGuide {
  position: relative;
  border-right: 1px solid rgb(182, 181, 181);
}

.rightGuide2 {
  position: relative;
  border-right: 3px solid #b6b5b5;
}
.sub_Cate_Box {
  top: 110%;
}
/* main list */
.cardImgRatio {
  padding-top: 100%;
}
/* ------------- JOIN */
.holder_main::placeholder {
  color: #8a6a59;
  font-weight: bold;
}
.holder_sub::placeholder {
  color: rgba(140, 189, 203, 0.7);

  font-weight: bold;
}

/* ---------- class Detail  */

/* main image */
.thumGrid1 {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}
.thumGrid2 {
  display: grid;
  grid-template-rows: 1.2fr 2fr;
}
.fit_ratio {
  padding-top: calc(100% * 696 / 888);
}

/* Mypage */
.MypageRatio {
  padding-top: calc(100% * 3 / 4);
}
.w-remain {
  width: calc(100% - 300px);
}

/* quill */

.ql-editor {
  padding: 0 !important;
}
.ql-editor ul,
.ql-editor img,
.ql-editor ol {
  display: inline-block;
  width: 100%;
}
.ql-editor img {
  object-fit: cover;
}

#class_intro .ql-tooltip,
#class_blog .ql-tooltip {
  display: none;
}
/* .ql-editor .ql-font-pretendardR {
  font-family: "pretendardR"!important;
}.ql-editor .ql-font-noto {
  font-family: "noto"!important;
}

.ql-picker.ql-font .ql-picker-label[data-value="noto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="noto"]::before
{
    font-family: "noto"!important;
    content: "noto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="pretendardR"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="pretendardR"]::before
{
    font-family: "pretendardR"!important ;
    content: "pretendardR" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="nanum"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="nanum"]::before
{
    font-family: "nanum"!important ;
    content: "nanum" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="gangwon"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="gangwon"]::before
{
    font-family: "gangwon"!important ;
    content: "gangwon" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="sansation"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="sansation"]::before
{
    font-family: "sansation"!important ;
    content: "sansation" !important;
} */

/* instagram */

#ins > div {
  border-radius: 1rem;
  min-width: 104px;
}
#ins img {
  aspect-ratio: 104 / 128;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #e2e2e2;
}
